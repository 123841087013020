import React from 'react';

import './side-bar.scss';

function SidebarFooter() {
  return (
    <div className="p-3 side-bar-footer">
      <a
        className="text-center"
        href="https://winred.com/request-a-committee"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>Can&apos;t find who you&apos;re looking for?</div>
        <div>Click here.</div>
      </a>
    </div>
  );
}

export default SidebarFooter;
