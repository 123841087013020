const isDevelopment = process.env.NODE_ENV !== 'production';

export const sendToIntercom = () => {
  const intercomUser = {
    app_id: 'l32kd5zj',
  };

  // eslint-disable-next-line no-console
  if (isDevelopment) console.debug('intercom', intercomUser);
  window.Intercom('boot', intercomUser);
};
