import React from 'react';
import PropTypes from 'prop-types';

import USAMap from './map';
import './map/map.scss';
import scssColors from 'styles/design_system/colors.scss';

const buildMapColors = (selectedStateAbbr, matchingStateAbbreviations) => {
  // add colors for states that match filter
  return matchingStateAbbreviations.reduce((mapConfig, stateAbbr) => {
    const color = stateAbbr === selectedStateAbbr ? scssColors['winred-red-dark'] : scssColors.geraldine;
    mapConfig[stateAbbr] = { fill: color };
    return mapConfig;
  }, {});
};

function MapComponent({ matchingStateAbbreviations, filterValues: { state }, onSelectState }) {
  return (
    <div className="directory-map text-center">
      <div className="text-center">
        <USAMap
          customize={buildMapColors(state, matchingStateAbbreviations)}
          onClick={onSelectState}
          width="100%"
          height="100%"
          defaultFill={scssColors.winredstategray}
        />
      </div>
    </div>
  );
}

MapComponent.propTypes = {
  onSelectState: PropTypes.func.isRequired,
  filterValues: PropTypes.object.isRequired,
  matchingStateAbbreviations: PropTypes.array.isRequired,
};

export default MapComponent;
