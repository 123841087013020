import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { setTitle, BodyComponent, sendToIntercom } from 'common';
import MapComponent from './map.component';
import SideBarComponent from './side-bar.component';
import FilterComponent from './filter.component';
import cloneDeep from 'lodash/cloneDeep';
import { buildOfficeFilterSet, stateNationalRevvUids, sortCandidates, federalNationalRevvUids } from './tools';

import './index.scss';

class DirectoryComponent extends Component {
  state = {
    filterValues: {
      state: '',
      offices: ['all'],
      searchInput: '',
    },
    allOffices: [],
    loading: true,
    matchingStateAbbreviations: [],
    matchingSidebarCandidates: {},
  };

  componentDidMount() {
    this.setFilterValues(this.props.validCandidates);
    sendToIntercom();
  }

  /**
   * sets the values the filter can have then triggers the filter results
   */
  setFilterValues = allCandidates => {
    const allOffices = buildOfficeFilterSet(allCandidates);
    this.setState({ allOffices, loading: false }, this.getFilterResults);
  };

  /**
   * update search input filter value and update the filtered candidates
   */
  onChangeSearchInput = ({ target: { value } }) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          searchInput: value,
        },
      },
      this.getFilterResults
    );
  };

  /**
   * change a filter value then get the filter's candidate results
   */
  onChangeFilter = (key, value) => {
    this.setState(
      {
        filterValues: {
          ...this.state.filterValues,
          [key]: value,
        },
      },
      this.getFilterResults
    );
  };

  /**
   * toggle selected offices
   */
  onSelectFilterOffice = ({ target: { name, type } }) => {
    // event handler is on button group but we only want to activate
    // this event if clicks button not group (whitespace)
    if (type !== 'button') return;

    const { offices } = this.state.filterValues;
    let newOffices;
    // if selected all then filter is all else filter out all
    // just in case it's there then either toggle out or add
    // in 'new' value if already in filter or not
    if (name === 'all') {
      newOffices = ['all'];
    } else {
      newOffices = offices.filter(office => office !== 'all');

      if (offices.includes(name)) {
        newOffices = offices.filter(office => office !== name);
      } else {
        newOffices = [...newOffices, name];
      }
    }

    // always include state if federal is selected or remove if not selected
    if (newOffices.includes('national_committee')) {
      newOffices.push('state_national_committee');
    } else if (name === 'national_committee') {
      newOffices = newOffices.filter(office => office !== 'state_national_committee');
    }

    // if deselected everything then default is all
    if (newOffices.length === 0) {
      newOffices = ['all'];
    }

    this.onChangeFilter('offices', newOffices);
  };

  /**
   * toggle selected state
   */
  onSelectState = (event, { abbreviation }) => {
    // if clicking the same state then we are unfiltering the state
    // or if all states then clear filter as well
    const currentSelectedState = this.state.filterValues.state;
    if ((currentSelectedState && currentSelectedState === abbreviation) || abbreviation === 'all') {
      abbreviation = '';
    }

    this.onChangeFilter('state', abbreviation);
  };

  /**
   * gets shown data based on filter values
   */
  getFilterResults() {
    const { offices, state, searchInput } = this.state.filterValues;

    // get states that will be highlighted on map
    let validCandidates = [...this.props.validCandidates];
    if (!offices.includes('all')) {
      validCandidates = validCandidates.filter(candidate => offices.includes(candidate.office));
    }

    // further filter by state to get sidebar matches
    let matchingSidebarCandidates = cloneDeep(validCandidates);
    if (state) {
      matchingSidebarCandidates = matchingSidebarCandidates.filter(candidate => {
        // National candidates should always be displayed
        if (candidate.office === 'president' || candidate.office === 'national_committee') return true;

        return candidate.state === state || !candidate.state;
      });
    }

    // if search bar given then filter candidate names by that
    if (searchInput) {
      const lowerCasedSearchInput = searchInput.toLowerCase();
      matchingSidebarCandidates = matchingSidebarCandidates.filter(candidate => {
        return candidate.searchName.includes(lowerCasedSearchInput);
      });
    }

    // format data for sidebar
    matchingSidebarCandidates = matchingSidebarCandidates.reduce((acc, curr) => {
      if (!acc[curr.office]) {
        acc[curr.office] = [];
      }
      acc[curr.office].push(curr);
      return acc;
    }, {});

    // for each sidebar section - sort candidates by state then district
    matchingSidebarCandidates = Object.entries(matchingSidebarCandidates).reduce((acc, [officeName, candidates]) => {
      // for state national committee we want a certain order
      if (['state_national_committee'].includes(officeName)) {
        const rga = candidates.find(
          candidate => candidate.revv_uid === 'b574e3dd414c2ef404703bdea083e28a1f81ed04a95a99fa041360898dda7e70'
        );
        const raga = candidates.find(
          candidate => candidate.revv_uid === '4d5a8d7199879d7a5efbea7c73f604dd541cda414e877c4b9aa51487a3c3357f'
        );
        const rslc = candidates.find(
          candidate => candidate.revv_uid === '5b126f2e139d84f219aa90bf043d8ad49e4f75519f6ecfeaac75d6d1afd982bb'
        );
        const topCandidates = [rga, raga, rslc].filter(c => Boolean(c));

        // get the rest of the values and sort them
        let nonmatchingCandidates = candidates.filter(candidate => !stateNationalRevvUids.includes(candidate.revv_uid));
        nonmatchingCandidates.sort(sortCandidates);

        // combine top items with the rest
        candidates = [...topCandidates, ...nonmatchingCandidates];
      } else if (['national_committee'].includes(officeName)) {
        const first = candidates.find(
          candidate => candidate.revv_uid === 'a0c8c0094f5a109245f6086db514b9d897bb6a0078b43a521bc431dac9fcbc7d'
        );
        const second = candidates.find(
          candidate => candidate.revv_uid === '390c8733f8554c34ccbdb3eebe245aab7224cf7d6ada71c22468251c0f33243e'
        );
        const third = candidates.find(
          candidate => candidate.revv_uid === 'ae440f5c7ca254f0150ca89668592e0ddee1f225af9f4308f007003b84fa09eb'
        );
        const topCandidates = [first, second, third].filter(c => Boolean(c));

        let nonmatchingCandidates = candidates.filter(
          candidate => !federalNationalRevvUids.includes(candidate.revv_uid)
        );
        nonmatchingCandidates.sort(sortCandidates);

        candidates = [...topCandidates, ...nonmatchingCandidates];
      } else {
        candidates.sort(sortCandidates);
      }

      acc[officeName] = candidates;
      return acc;
    }, {});

    // get abbr only for map before saving
    const stateAbbrs = validCandidates.map(state => state.state);
    this.setState({
      matchingStateAbbreviations: stateAbbrs,
      matchingSidebarCandidates,
    });
  }

  render() {
    const { filterValues, allOffices, matchingSidebarCandidates, matchingStateAbbreviations } = this.state;

    return (
      <BodyComponent>
        <Container fluid="true">
          {setTitle('Directory')}
          <Row className="justify-content-center">
            <Col xl={7} lg={6} md={7} sm={12} className="svg-map">
              <div className="text-center">
                <div className="map-title">Find candidates and committees on WinRed</div>
              </div>
              <MapComponent
                onSelectState={this.onSelectState}
                filterValues={filterValues}
                matchingStateAbbreviations={matchingStateAbbreviations}
              />
              <FilterComponent
                filterValues={filterValues}
                allOffices={allOffices}
                onSelectFilterOffice={this.onSelectFilterOffice}
              />
            </Col>

            <Col xl={3} lg={4} md={5} sm={12}>
              <SideBarComponent
                matchingStateAbbreviations={matchingStateAbbreviations}
                onSelectState={this.onSelectState}
                filterValues={filterValues}
                onChangeSearchInput={this.onChangeSearchInput}
                matchingSidebarCandidates={matchingSidebarCandidates}
              />
            </Col>
          </Row>
        </Container>
      </BodyComponent>
    );
  }
}

DirectoryComponent.propTypes = {
  validCandidates: PropTypes.array.isRequired,
};

export default DirectoryComponent;
