import React from 'react';
import PropTypes from 'prop-types';

const USAState = ({ isNotFiltered, state, dimensions, fill, onClickState, stateName }) => {
  return (
    <path
      aria-labelledby={state}
      d={dimensions}
      fill={fill}
      data-name={state}
      className={`${state} state ${isNotFiltered ? 'unfiltered-state' : ''}`}
      onClick={isNotFiltered ? onClickState : null}
    >
      <title>{stateName}</title>
    </path>
  );
};

USAState.propTypes = {
  isNotFiltered: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  dimensions: PropTypes.any.isRequired,
  fill: PropTypes.string.isRequired,
  onClickState: PropTypes.func,
  stateName: PropTypes.string.isRequired,
};

export default USAState;
