import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { CUSTOM_ICONS, FA_ICONS } from 'utilities/icon-loader';

const COLOR_CLASS_MAP = {
  default: 'c--lynx',
  inactive: 'c--pickled-bluewood-light',
  blue: 'c--winred-blue-light',
  green: 'c--winred-green',
  gold: 'c--rajah',
  gray: 'c--nepal',
  white: 'c--white',
  black: 'c--black',
  red: 'c--winred-red',
  orange: 'c--sunset-orange',
};

const Icon = props => {
  const { icon, size, className, color, ...restProps } = props;
  const useColor = color || 'default';
  const colorClass = COLOR_CLASS_MAP[useColor];

  const customIcon = CUSTOM_ICONS[icon];
  if (customIcon) {
    // custom icons don't support the `size` prop yet
    return <img className={classNames('winred-icon', className)} src={customIcon} alt="" {...restProps} />;
  }

  const faIcon = FA_ICONS[icon];
  if (!faIcon) return null;

  return (
    <FontAwesomeIcon
      className={classNames(colorClass, className)}
      icon={[faIcon.prefix, icon]}
      size={size}
      {...restProps}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Icon;
