import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from 'assests/images/WinRed-full-red.png';
import './nav-bar.scss';
import './nav-items.scss';
import 'common/signup.scss';

function NavBarMain() {
  return (
    <Container fluid="true">
      <Row className="justify-content-center">
        <Col lg="10">
          <div className="top-nav-bar">
            <div className="mainnav">
              <Navbar expand="lg" className="winred-nav">
                <Nav.Item>
                  <Nav.Link href="/" className="winred-nav-logo">
                    <img width={90} src={logo} alt="logo" />
                  </Nav.Link>
                </Nav.Item>

                <Navbar.Toggle aria-controls="basic-navbar-nav" data-target="#basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ml-auto">
                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link href="/" className="winred-nav__nav-link">
                        Directory
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link href="http://winred.com/about" className="winred-nav__nav-link">
                        About
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link href="https://winred.com/blog" className="winred-nav__nav-link">
                        Blog
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link href="https://winred.com/careers" className="winred-nav__nav-link">
                        Careers
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link
                        href="https://secure.winred.com/winred-pac/storefront/"
                        className="winred-nav__nav-link"
                      >
                        Store
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link href="https://winred.com/support/" className="winred-nav__nav-link">
                        Donor Support
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link href="https://portal.winred.com/" className="btn-signin">
                        Sign In
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="winred-nav__nav-item">
                      <Nav.Link href="https://winred.com/register/" className="btn-signup">
                        Sign Up
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NavBarMain;
