// these are the only offices that will ever be included in the directory
export const validOfficesRegEx = new RegExp(
  /^(president|senate|house|national_committee|state_committee|state_governor|state_executive|state_senate|state_house|state_local|state_other|state_national_committee|pac)$/
);

// these are offices we want to convert to title casing - others we want to leave as is because they may be all upper casing
const noAllCapsOffices = [
  'state_committee',
  'state_governor',
  'state_executive',
  'state_senate',
  'state_house',
  'state_local',
  'state_other',
];

// these two are hard coded ids to force them intoi a certain order
export const federalNationalRevvUids = [
  'a0c8c0094f5a109245f6086db514b9d897bb6a0078b43a521bc431dac9fcbc7d',
  '390c8733f8554c34ccbdb3eebe245aab7224cf7d6ada71c22468251c0f33243e',
  'ae440f5c7ca254f0150ca89668592e0ddee1f225af9f4308f007003b84fa09eb',
];

export const stateNationalRevvUids = [
  'b574e3dd414c2ef404703bdea083e28a1f81ed04a95a99fa041360898dda7e70',
  '4d5a8d7199879d7a5efbea7c73f604dd541cda414e877c4b9aa51487a3c3357f',
  '5b126f2e139d84f219aa90bf043d8ad49e4f75519f6ecfeaac75d6d1afd982bb',
];

export const sortCandidates = (a, b) => {
  return a.tag > b.tag ? 1 : -1;
};

// after checking valid offices, we always want to include these offices in the search filter results
export const alwaysIncludedOffices = ['national_committee', 'state_committee'];

export const filterValidCandidates = candidates => {
  return candidates
    .filter(candidate => {
      const name = (candidate.first_name && candidate.last_name) || candidate.name || '';

      // always needs a name
      if (!name) return false;

      // needs to be a valid office
      if (!candidate.office || !candidate.office.match(validOfficesRegEx)) return false;

      // if needs state and district
      const districtOffices = ['house'];
      if (districtOffices.includes(candidate.office) && (!candidate.state || !candidate.district)) return false;

      // if needs state only
      const stateOffices = ['senate', 'state_committee', 'state_governor', 'state_senate', 'state_executive'];
      if (stateOffices.includes(candidate.office) && !candidate.state) return false;

      return true;
    })
    .map(candidate => {
      const name =
        candidate.first_name && candidate.last_name ? `${candidate.first_name} ${candidate.last_name}` : candidate.name;

      candidate.searchName = name.toLowerCase();

      // if a name is in all caps we want to get rid of that
      candidate.displayName = name;
      if (noAllCapsOffices.includes(candidate.office) && isUpperCase(candidate.displayName)) {
        candidate.displayName = candidate.displayName.toLowerCase();
      }

      candidate.searchName = name.toLowerCase();

      // hard code
      if (candidate.revv_uid === 'a8bcdac01e0608f47ebaf87621bfcdef2afd7405f82571ec6ea8b7a6aba34587') {
        candidate = {
          ...candidate,
          office: 'president',
          name: 'donald j. trump',
          displayName: 'Donald J. Trump',
          searchName: 'donald j. trump',
          sidebarCandidateDescription: 'President',
        };
      }

      return candidate;
    });
};

function isUpperCase(str) {
  return str === str.toUpperCase();
}

export const OFFICE_MAP = {
  president: { filterOfficeName: 'President', sidebarCandidateDescription: () => 'President' },
  national_committee: {
    filterOfficeName: 'National Committees',
    sidebarCandidateDescription: () => 'National Committees',
  },
  state_committee: {
    filterOfficeName: 'State Party',
    sidebarCandidateDescription: () => 'State Party',
  },
  senate: {
    filterOfficeName: 'U.S. Senate',
    sidebarCandidateDescription: candidate => `${candidate.state}-SEN`,
  },
  house: {
    filterOfficeName: 'U.S. House',
    sidebarCandidateDescription: candidate => `${candidate.state}-${candidate.district}`,
  },
  state_national_committee: {
    filterOfficeName: 'National Committee',
    sidebarCandidateDescription: candidate => `${candidate.state}`,
  },
  state_governor: {
    filterOfficeName: 'Governor',
    sidebarCandidateDescription: candidate => `${candidate.state}`,
  },
  state_executive: {
    filterOfficeName: 'State Executive',
    sidebarCandidateDescription: candidate => `${candidate.state}`,
  },
  state_senate: {
    filterOfficeName: 'State Senator',
    sidebarCandidateDescription: candidate => `${candidate.state}`,
  },
  state_house: {
    filterOfficeName: 'State House',
    sidebarCandidateDescription: candidate => `${candidate.state}`,
  },
  state_local: {
    filterOfficeName: 'Local',
    sidebarCandidateDescription: candidate => `${candidate.state}`,
  },
  state_other: {
    filterOfficeName: 'Other',
    sidebarCandidateDescription: candidate => `${candidate.state}`,
  },
};

/**
 * get office name from a candidate's office position
 */
export const formatOfficeName = candidate => {
  // if canddiate has an override value use that instead
  if (candidate.sidebarCandidateDescription) return candidate.sidebarCandidateDescription;

  const officeSettings = OFFICE_MAP[candidate.office];
  if (!officeSettings) return '';
  return officeSettings.sidebarCandidateDescription(candidate);
};

export const formatOfficeNameForFilter = office => {
  const officeSettings = OFFICE_MAP[office];
  if (officeSettings) return officeSettings.filterOfficeName;
  return office.replace(/_|-/g, ' ');
};

export const buildOfficeFilterSet = allCandidates => {
  // get all offices but then only allow certain valid ones
  let allOffices = allCandidates
    .map(candidate => candidate.office)
    .filter(office => office && office.match(validOfficesRegEx));

  allOffices = [...new Set([...allOffices, ...alwaysIncludedOffices])];

  // build the exact order we want
  const officeFilterOrdered = ['all'];
  if (allOffices.includes('president')) officeFilterOrdered.push('president');
  if (allOffices.includes('national_committee')) officeFilterOrdered.push('national_committee');
  if (allOffices.includes('state_committee')) officeFilterOrdered.push('state_committee');
  if (allOffices.includes('senate')) officeFilterOrdered.push('senate');
  if (allOffices.includes('house')) officeFilterOrdered.push('house');
  if (allOffices.includes('state_governor')) officeFilterOrdered.push('state_governor');
  if (allOffices.includes('state_executive')) officeFilterOrdered.push('state_executive');
  if (allOffices.includes('state_senate')) officeFilterOrdered.push('state_senate');
  if (allOffices.includes('state_house')) officeFilterOrdered.push('state_house');
  if (allOffices.includes('state_local')) officeFilterOrdered.push('state_local');
  if (allOffices.includes('state_other')) officeFilterOrdered.push('state_other');
  return officeFilterOrdered;
};
