import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const FloatGroupContext = React.createContext({
  setActive: () => {},
  active: null,
  shouldFloat: false,
});

class FloatGroup extends Component {
  state = {
    active: false,
  };

  setActive = active => this.setState({ active });

  render() {
    const { children, floatLabel = null, ...rest } = this.props;

    return (
      <FloatGroupContext.Provider
        value={{
          setActive: this.setActive.bind(this),
          active: floatLabel !== null ? floatLabel : this.state.active,
          shouldFloat: true,
        }}
      >
        <Form.Group {...rest}>
          <div className="float-label">{children}</div>
        </Form.Group>
      </FloatGroupContext.Provider>
    );
  }
}

class Label extends Component {
  static contextType = FloatGroupContext;

  render() {
    const { children, className, ...rest } = this.props;
    const activeClass = this.context.active ? 'active' : '';
    const useClassName = classNames('placeholder', 'input', activeClass, className);
    return (
      <Form.Label {...rest} className={useClassName}>
        {children}
      </Form.Label>
    );
  }
}

FloatGroup.propTypes = {
  children: PropTypes.node.isRequired,
  floatLabel: PropTypes.string,
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FloatGroup.Context = FloatGroupContext;
FloatGroup.Label = Label;

export default FloatGroup;
