// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import MainLayout from 'modules/layout/main.layout';
import { buildIconLibrary } from 'utilities/icon-loader';
import packageJson from '../package.json';

import './index.scss';

buildIconLibrary();

// set version var to easily check
window.appVersion = packageJson.version;
// eslint-disable-next-line no-console
console.log(`WinRed Directory Version ${window.appVersion}`);

ReactDOM.render(<MainLayout />, document.getElementById('root'));
