import React from 'react';
import PropTypes from 'prop-types';

import './body.scss';

function BodyComponent({ children }) {
  return <div className="mt-5 mb-5">{children}</div>;
}

BodyComponent.propTypes = {
  children: PropTypes.any.isRequired,
};

export default BodyComponent;
