import React from 'react';
import PropTypes from 'prop-types';
import mapData from './data';
import USAState from './USAState';

function USAMap({ customize, defaultFill, onClick, width, height }) {
  /**
   * fills a state's background color either get the
   * custom config for a state or use the default config
   */
  const fillStateColor = abbreviation => {
    if (customize && customize[abbreviation] && customize[abbreviation].fill) {
      return customize[abbreviation].fill;
    }

    return defaultFill;
  };

  /**
   * states that are filtered out need to not have a click event nor pointer
   */
  const isNotFiltered = abbreviation => !!(customize && customize[abbreviation]);

  return (
    <svg
      className="us-state-map"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 959 593"
    >
      <g className="outlines">
        {Object.values(mapData).map(state => {
          const { name, abbreviation, dimensions } = state;
          if (!dimensions) return null;

          return (
            <USAState
              key={abbreviation}
              stateName={name}
              dimensions={dimensions}
              state={abbreviation}
              fill={fillStateColor(abbreviation)}
              isNotFiltered={isNotFiltered(abbreviation)}
              onClickState={event => onClick(event, state)}
            />
          );
        })}
      </g>
    </svg>
  );
}

USAMap.propTypes = {
  onClick: PropTypes.func.isRequired,
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired,
  defaultFill: PropTypes.string.isRequired,
  customize: PropTypes.object.isRequired,
};

export default USAMap;
