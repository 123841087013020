import React from 'react';
import PropTypes from 'prop-types';

function ScrollButtons({ scrollToFederalLevel, scrollToStateLevel }) {
  return (
    <div className="card-section card-section-header scroll-butn-container">
      <button className="btn scroll-btn" onClick={scrollToFederalLevel}>
        Federal Races
      </button>
      <button className="btn scroll-btn" onClick={scrollToStateLevel}>
        State Races
      </button>
    </div>
  );
}

ScrollButtons.propTypes = {
  scrollToFederalLevel: PropTypes.func.isRequired,
  scrollToStateLevel: PropTypes.func.isRequired,
};

export default ScrollButtons;
