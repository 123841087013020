import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import chunk from 'lodash/chunk';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { Icon } from 'common';
import mapData from '../map/data';
import './side-bar.scss';
import './state-selector.scss';

const rows = chunk(Object.keys(mapData), 10).map(row => {
  return row.map(stateAbbr => {
    return mapData[stateAbbr];
  });
});

function StateSelector({ selectedState, onSelectState, matchingStateAbbreviations }) {
  const [isOpen, setIsOpen] = useState(false);
  const stateSelectorRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleStateSelector = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = event => {
    if (stateSelectorRef && !stateSelectorRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const _onSelectState = event => {
    event.preventDefault();
    event.stopPropagation();

    // dont do anything if state isnt even selectable
    const isSelectable = event.target.dataset.selectable;
    if (isSelectable === 'false') return;

    // only allow to click on enabled states from filter - always allow all state though
    const selectedState = event.target.dataset.state;
    if (selectedState !== 'all' && !matchingStateAbbreviations.includes(selectedState)) return;

    onSelectState(event, { abbreviation: selectedState });
    setIsOpen(false);
  };

  // get details of selected state
  const selectedStateDetails = mapData[selectedState] || {};

  return (
    <div
      className="card-section card-section-header"
      ref={stateSelectorRef}
      onClick={toggleStateSelector}
      onKeyPress={toggleStateSelector}
      role="button"
      tabIndex="0"
    >
      <Row className="header-section">
        <Col>
          <h1>{selectedStateDetails.name || 'Choose State'}</h1>
        </Col>

        <div className="state-selector">
          <Col xs="auto">
            <Button className="state-button">
              <Icon icon="angle-down" color="white" />
            </Button>
          </Col>
          {isOpen ? (
            <div className="state-selector d-relative">
              <div
                className="d-flex flex-column state-selector-pop-up pop-up"
                onClick={_onSelectState}
                onKeyPress={_onSelectState}
                role="button"
                tabIndex="0"
              >
                <div className="d-flex flex-row justify-content-between">
                  <div className="states-title">Choose State</div>
                  <div
                    className={classNames('all-states-row p-1', {
                      'selected-state': !selectedState,
                    })}
                  >
                    <div key="all" data-state="all">
                      All States
                    </div>
                  </div>
                </div>

                {rows.map((row, index) => {
                  return (
                    <div className="d-flex flex-row" key={index}>
                      {row.map(state => {
                        const isSelectable = matchingStateAbbreviations.includes(state.abbreviation);
                        const isSelectedState = state.abbreviation === selectedState;

                        return (
                          <div
                            title={state.name}
                            className={classNames('state-item', {
                              'selected-state': isSelectedState,
                              selectable: isSelectable,
                            })}
                            key={state.abbreviation}
                            data-state={state.abbreviation}
                            data-selectable={isSelectable}
                          >
                            {state.abbreviation}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </Row>
    </div>
  );
}

StateSelector.propTypes = {
  selectedState: PropTypes.string.isRequired,
  onSelectState: PropTypes.func.isRequired,
  matchingStateAbbreviations: PropTypes.array.isRequired,
};

export default StateSelector;
