import React from 'react';
import PropTypes from 'prop-types';

import Input from 'common/components/input.component';
import InputGroup from 'react-bootstrap/InputGroup';
import { Icon } from 'common';

function SearchCandidates({ onChangeSearchInput, searchInput }) {
  return (
    <div className="p-3 search-candidates">
      <InputGroup>
        <InputGroup.Prepend>
          <Icon className="search-icon" icon="search" />
        </InputGroup.Prepend>
        <Input onChange={onChangeSearchInput} value={searchInput} placeholder="Search Committees" />
        {searchInput ? (
          <Icon className="close-btn" icon="times" onClick={() => onChangeSearchInput({ target: { value: '' } })} />
        ) : null}
      </InputGroup>
    </div>
  );
}

SearchCandidates.propTypes = {
  onChangeSearchInput: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
};

export default SearchCandidates;
