import React from 'react';

import { NavBar, Footer } from 'common';
import Directory from 'modules/directory';
import './layout.scss';

function MainContainer() {
  return (
    <div className="flex-wrapper">
      <div className="flex-grow-1">
        <NavBar />
        <Directory />
      </div>
      <Footer />
    </div>
  );
}

export default MainContainer;
