import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import defaultCandidateAvatar from 'assests/images/thumb_shield-fallback.png';
import { formatOfficeName } from '../tools';

function SideBarSection({ candidates, office }) {
  return (
    <div className="card-section">
      <Row>
        <Col sm={12}>
          <h2>{office}</h2>
        </Col>
      </Row>
      {candidates.map(candidate => {
        const office = formatOfficeName(candidate);
        const avatar = candidate.avatar || defaultCandidateAvatar;
        const donateUrl = candidate.default_page_url
          ? `${candidate.default_page_url}?sc=winred-directory&money_bomb=false&recurring=false`
          : null;

        return (
          <Row className="section-candidate" key={candidate.revv_uid}>
            <Col xs="auto" className="section-image">
              <Image decoding="async" src={avatar} roundedCircle fluid className="candidate-avatar" />
            </Col>
            <Col className="section-info p-0">
              <div className="name text-break">{candidate.displayName}</div>
              <div className="office">{office}</div>
            </Col>
            <Col xs="auto" className="section-btn">
              {candidate.storefront_url && (
                <a href={candidate.storefront_url} target="_blank" rel="noopener noreferrer" className="mr-1">
                  <Button size="sm">Shop</Button>
                </a>
              )}
              {donateUrl && (
                <a href={donateUrl} target="_blank" rel="noopener noreferrer">
                  <Button size="sm">Donate</Button>
                </a>
              )}
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

SideBarSection.propTypes = {
  candidates: PropTypes.array.isRequired,
  office: PropTypes.string.isRequired,
};

export default SideBarSection;
