import React, { useState, useEffect } from 'react';
import 'whatwg-fetch';

import { Spinner } from 'common';
import { filterValidCandidates } from './tools';
import Directory from './directory.component';
import './index.scss';

const API_ENDPOINT = process.env.REACT_APP_API;

function DirectoryContainer() {
  const [status, setStatus] = useState({ loading: true, error: null, validCandidates: [] });

  useEffect(() => {
    fetch(API_ENDPOINT)
      .then(response => response.json())
      .then(response => {
        setStatus({ error: null, loading: false, validCandidates: filterValidCandidates(response) });
      })
      .catch(error => {
        setStatus({ error, loading: false, validCandidates: [] });
        // eslint-disable-next-line no-console
        console.log({ error });
      });
  }, [setStatus]);

  if (status.error) return null;
  if (status.loading) return <Spinner />;
  return <Directory validCandidates={status.validCandidates} />;
}

export default DirectoryContainer;
