import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Icon } from 'common';
import logo from 'assests/images/WinRed-full-red.png';
import './index.scss';
import 'common/signup.scss';

function Footer() {
  return (
    <>
      {/* top footer */}
      <Container fluid="true">
        <Row className="justify-content-center footer-top">
          <Col lg="10">
            <Row className="justify-content-center">
              <Col xs="auto">
                <a href="/" className="footer-logo">
                  <img width={90} src={logo} alt="" />
                </a>
              </Col>

              <Col xs="auto" className="d-none d-sm-flex ml-auto footer-links">
                <div className="footer-item">
                  <a href="/" target="_blank" rel="noopener noreferrer" className="footer-link">
                    Directory
                  </a>
                </div>

                <div className="footer-item">
                  <a href="http://winred.com/about" target="_blank" rel="noopener noreferrer" className="footer-link">
                    About
                  </a>
                </div>

                <div className="footer-item">
                  <a href="https://winred.com/blog" target="_blank" rel="noopener noreferrer" className="footer-link">
                    Blog
                  </a>
                </div>

                <div className="footer-item">
                  <a
                    href="https://winred.com/careers"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    Careers
                  </a>
                </div>

                <div className="footer-item">
                  <a
                    href="https://secure.winred.com/storefront/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    Store
                  </a>
                </div>

                <div className="footer-item">
                  <a
                    href="https://winred.com/support/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    Donor Support
                  </a>
                </div>

                <div className="footer-item">
                  <a href="https://portal.winred.com/" target="_blank" rel="noopener noreferrer" className="btn-signin">
                    Sign In
                  </a>
                </div>

                <div className="footer-item">
                  <a
                    href="https://winred.com/register/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-signup"
                  >
                    Sign Up
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* bottom footer */}
      <Container fluid="true">
        <Row className="justify-content-center footer-bottom">
          <Col lg="10">
            <Row className="justify-content-between">
              <Col sm="auto" className="text-center copyright">
                <div className="text-footer">
                  © {new Date().getFullYear()} WinRed. All rights reserved.
                  <a className="ml-3" href="https://winred.com/privacy/" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  <a
                    className="ml-3"
                    href="https://winred.com/terms/donor-terms/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </a>
                  <a className="ml-3" href="https://winred.com/contact/" target="_blank" rel="noopener noreferrer">
                    Contact
                  </a>
                </div>
              </Col>
              <Col sm="auto" className="text-center copyright">
                <a href="https://twitter.com/winred" target="_blank" rel="noopener noreferrer">
                  <Icon icon="twitter-square" className="" size="2x" color="white" />
                </a>
                <a href="https://www.instagram.com/winredpac/" target="_blank" rel="noopener noreferrer">
                  <Icon icon="instagram" className="ml-2" size="2x" color="white" />
                </a>
                <a href="https://www.facebook.com/WinRed/" target="_blank" rel="noopener noreferrer">
                  <Icon icon="facebook-square" className="ml-2" size="2x" color="white" />
                </a>
                <a href="https://www.linkedin.com/company/winredpac" target="_blank" rel="noopener noreferrer">
                  <Icon icon="linkedin" className="ml-2" size="2x" color="white" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* centered text */}
        <Row className="d-flex justify-content-center copyright footer-bottom">
          <Col lg="10" className="text-center mb-3">
            Paid for by WinRed. Not authorized by any candidate or candidate&apos;s committee. winred.com
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
