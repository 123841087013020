import React from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './filter.scss';
import { formatOfficeNameForFilter } from './tools';
function FilterComponent(props) {
  const { onSelectFilterOffice, filterValues, allOffices } = props;

  return (
    <div className="directory-filter">
      <Row>
        <Col className="election-type">
          <div className="d-block mb-1">Office</div>
          <ButtonGroup onClick={onSelectFilterOffice} className="d-block" size="sm" aria-label="Election Type Filter">
            {allOffices.map(office => {
              const variant = filterValues.offices.includes(office) ? 'danger' : '';
              return (
                <Button className="text-capitalize" key={office} variant={variant} name={office}>
                  {formatOfficeNameForFilter(office)}
                </Button>
              );
            })}
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
}

FilterComponent.propTypes = {
  filterValues: PropTypes.object.isRequired,
  allOffices: PropTypes.array.isRequired,
  onSelectFilterOffice: PropTypes.func.isRequired,
};

export default FilterComponent;
