import React from 'react';
import { Helmet } from 'react-helmet';

function setTitle(title) {
  return (
    <Helmet>
      <title>{title} | WinRed</title>
    </Helmet>
  );
}

export default setTitle;
