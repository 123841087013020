import { library } from '@fortawesome/fontawesome-svg-core';

import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

import {} from '@fortawesome/pro-solid-svg-icons';

import { faTwitterSquare, faFacebookSquare, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const CUSTOM_ICONS = {};

export const FA_ICONS = [
  faAngleDown,
  faTwitterSquare,
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faSearch,
  faTimes,
].reduce((icons, icon) => {
  icons[icon.iconName] = icon;
  return icons;
}, {});

export const buildIconLibrary = () => {
  library.add(...Object.values(FA_ICONS));
};
