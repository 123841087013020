import React from 'react';
import PropTypes from 'prop-types';

import './floating-card.scss';

function FloatingCard({ children, className }) {
  return <div className={'floating-card ' + (className || '')}>{children}</div>;
}

FloatingCard.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default FloatingCard;
