import React from 'react';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';

import ListGroup from 'react-bootstrap/ListGroup';

import { FloatingCard } from 'common';
import { StateSelector, SideBarSection, SearchCandidates, SidebarFooter, ScrollButtons } from './side-bar';
import './side-bar/side-bar.scss';
import { OFFICE_MAP } from './tools';

const SCROLL_OPTIONS = {
  duration: 500,
  delay: 0,
  smooth: 'easeInOutQuart',
  containerId: 'containerElement',
};

function SideBarComponent(props) {
  const {
    filterValues,
    onSelectState,
    onChangeSearchInput,
    matchingSidebarCandidates,
    matchingStateAbbreviations,
  } = props;

  const { state: selectedState, searchInput } = filterValues;
  const noResults = Object.keys(matchingSidebarCandidates).length === 0;

  const scrollToFederalLevel = () => {
    scroller.scrollTo('federalLevel', SCROLL_OPTIONS);
  };

  const scrollToStateLevel = () => {
    scroller.scrollTo('stateLevel', SCROLL_OPTIONS);
  };

  return (
    <div className="directory-side-bar">
      <FloatingCard>
        <SearchCandidates onChangeSearchInput={onChangeSearchInput} searchInput={searchInput} />
        <ScrollButtons scrollToFederalLevel={scrollToFederalLevel} scrollToStateLevel={scrollToStateLevel} />
        <StateSelector
          selectedState={selectedState}
          onSelectState={onSelectState}
          matchingStateAbbreviations={matchingStateAbbreviations}
        />

        {/* show no results if searching by input */}
        {noResults && searchInput ? (
          <div className="text-center p-4 no-candidate-results">
            <div className="no-candidate-title mb-1">Whoops!</div>
            <div className="no-candidate-details">Sorry we can&apos;t find any candidate by that name.</div>
          </div>
        ) : null}

        <ListGroup className="sidebar-sections">
          <Element className="scroll-container" id="containerElement">
            <Element name="federalLevel">
              {matchingSidebarCandidates.president ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.president}
                    office={OFFICE_MAP.president.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.national_committee ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.national_committee}
                    office={OFFICE_MAP.national_committee.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.state_committee ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_committee}
                    office={OFFICE_MAP.state_committee.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.senate ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.senate}
                    office={OFFICE_MAP.senate.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.house ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.house}
                    office={OFFICE_MAP.house.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
            </Element>

            {/* state level orgs */}
            <Element name="stateLevel">
              {matchingSidebarCandidates.state_national_committee ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_national_committee}
                    office={OFFICE_MAP.state_national_committee.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.state_governor ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_governor}
                    office={OFFICE_MAP.state_governor.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.state_executive ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_executive}
                    office={OFFICE_MAP.state_executive.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.state_senate ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_senate}
                    office={OFFICE_MAP.state_senate.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.state_house ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_house}
                    office={OFFICE_MAP.state_house.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.state_local ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_local}
                    office={OFFICE_MAP.state_local.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
              {matchingSidebarCandidates.state_other ? (
                <ListGroup.Item>
                  <SideBarSection
                    candidates={matchingSidebarCandidates.state_other}
                    office={OFFICE_MAP.state_other.filterOfficeName}
                  />
                </ListGroup.Item>
              ) : null}
            </Element>
          </Element>
        </ListGroup>

        <SidebarFooter />
      </FloatingCard>
    </div>
  );
}

SideBarComponent.propTypes = {
  filterValues: PropTypes.object.isRequired,
  onSelectState: PropTypes.func.isRequired,
  onChangeSearchInput: PropTypes.func.isRequired,
  matchingStateAbbreviations: PropTypes.array.isRequired,
  matchingSidebarCandidates: PropTypes.object.isRequired,
};

export default SideBarComponent;
