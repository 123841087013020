import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

const Spinner = ({ overlay }) => (
  <div className={classnames('main-data-spinner', { 'spinner-overlay': overlay })}>
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

Spinner.propTypes = {
  overlay: PropTypes.bool,
};

Spinner.defaultProps = {
  overlay: false,
};

export default Spinner;
