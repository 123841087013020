import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

import FloatGroup from 'common/components/float-group.component';

class Input extends Component {
  render() {
    const { ariaLabel, placeholder, className, ...rest } = this.props;

    return (
      <FloatGroup.Context.Consumer>
        {floatGroup => (
          <Form.Control
            {...rest}
            aira-label={ariaLabel}
            className={classNames(
              'winred-input',
              {
                'winred-input--active': floatGroup.shouldFloat,
              },
              className
            )}
            placeholder={floatGroup.active ? '' : placeholder}
          />
        )}
      </FloatGroup.Context.Consumer>
    );
  }
}

Input.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Input.defaultProps = {
  ariaLabel: '',
};

export default Input;
